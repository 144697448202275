<template>
  <div>
    <div class="otp mt-6 flex w-full justify-around" @input="handleOtpInput">
      <template v-for="field in fields" :key="field">
        <input
          ref="firstInputEl"
          v-model="data[field - 1]"
          type="text"
          maxlength="1"
          class="flex h-12 w-12 flex-col items-center justify-center rounded-xl border border-gray-200 bg-white px-3 text-center text-lg outline-none ring-blue-700 focus:bg-gray-50 focus:ring-1"
          @paste="field === 1 && handlePaste($event)"
          @input="handlePinChange"
        />
      </template>
    </div>

    <slot />

    <div
      v-if="countdownInSecs === 60"
      :class="[
        {
          'pointer-events-none opacity-60': sendingOTP,
        },
      ]"
      class="mt-4 flex flex-row items-center justify-center space-x-1 text-center text-sm font-medium text-gray-500"
    >
      <p class="inline-flex">
        <img
          src="@/assets/svgs/resend.svg"
          class="mr-1"
          :class="[{ 'animate-spin': sendingOTP }]"
        />
        Didn't recieve code?
      </p>
      <button
        class="flex flex-row items-center text-blue-600"
        href="#"
        rel="noopener noreferrer"
        @click="resend"
      >
        Resend
      </button>
    </div>

    <div v-else class="mt-2 text-sm text-primary">
      <p>
        You can request for a new OTP in
        <span class="font-bold"> {{ countdownInSecs }} secs. </span>
      </p>
    </div>

    <p v-if="props.error" class="mt-2 text-sm font-normal text-[#F04438]">
      {{ error }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { useToast } from '../ui/toast'

const props = defineProps<{
  fields: number
  loading: boolean
  error: string
}>()

const data = ref([])
const firstInputEl = ref(null)
const emit = defineEmits(['update:modelValue'])

//   watch(
//     () => data,
//     (newVal) => {
//       if (
//         newVal.value != '' &&
//         newVal.value.length === props.fields &&
//         !newVal.value.includes('')
//       ) {
//         emit('update:modelValue', Number(newVal.value.join('')));
//       } else {
//         emit('update:modelValue', null);
//       }
//     },
//     { deep: true }
//   );

const handleOtpInput = (e) => {
  if (e.data && e.target.nextElementSibling) {
    e.target.nextElementSibling.focus()
  } else if (e.data == null && e.target.previousElementSibling) {
    e.target.previousElementSibling.focus()
  }
}

const handlePaste = (e) => {
  const pasteData = e.clipboardData.getData('text')
  let nextEl = firstInputEl.value[0].nextElementSibling
  for (let i = 1; i < pasteData.length; i++) {
    if (nextEl) {
      data.value[i] = pasteData[i]
      nextEl = nextEl.nextElementSibling
    }
  }
}

const handlePinChange = () => {
  emit('update:modelValue', data.value.join(''))
}

const { toast } = useToast()
const { CardPay } = usePaymentComposable()
const sendingOTP = ref(false)
const countdownInSecs = ref(60)
let countdownTimer: NodeJS.Timeout | null = null

function startCountdown() {
  if (countdownTimer === null) {
    countdownTimer = setInterval(() => {
      if (countdownInSecs.value > 0) {
        countdownInSecs.value--
      } else if (countdownTimer) {
        resetCountdown()
      }
    }, 1000)
  }
}

// Function to reset the countdown
function resetCountdown() {
  if (countdownTimer) {
    clearInterval(countdownTimer)
    countdownTimer = null
    countdownInSecs.value = 60
  }
}

const resend = async () => {
  try {
    sendingOTP.value = true
    await CardPay.generateSessionCode(() => startCountdown())
  } catch (error) {
    toast({
      title: 'OTP Error',
      description: 'Error occured while requesting OTP',
      variant: 'destructive',
    })
  } finally {
    sendingOTP.value = false
  }
}

onBeforeUnmount(() => {
  resetCountdown()
})
</script>
